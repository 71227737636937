import React from 'react'

const SearchInput = ({ searchQuery, setSearchQuery, placeholder, clearSearch, className }) => {
    
    return (
        <div className='relative mt-4 md:mt-0'>
            <input
                type="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`bg-white appearance-none border-b border-black rounded-0 w-full py-4 pl-0 pr-6 text-black leading-tight focus:outline-none focus:bg-white focus:border-red-500 ${className}`}
            />
            {searchQuery &&
                <div onClick={clearSearch} className='text-black absolute right-2 cursor-pointer' style={{ top: "24px" }}>
                    <span className='text-gray-400 work-sans text-sm' >Clear</span>
                </div>
            }
        </div>
    );
};

export default SearchInput;