import React, { useState } from "react";
import he from "he";

const AuthorFilter = ({
  searchAuthor,
  setSearchAuthor,
  filteredAuthors,
  selectedAuthors,
  handleAuthorToggle
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full mt-2 mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full border-b border-gray-200 pb-3 flex justify-between items-center text-sm work-sans text-black font-medium"
      >
        Authors
        <svg
          className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="pt-4">
          <input
            type="text"
            placeholder="Search authors..."
            value={searchAuthor}
            onChange={(e) => setSearchAuthor(e.target.value)}
            className="w-full p-2 mb-4 work-sans text-sm text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-red-500"
          />
          <div className="flex flex-wrap">
            {filteredAuthors.map(author => (
              <label key={author} className="flex items-center gap-2 mb-2 mr-4 cursor-pointer">
                <input
                  type="checkbox"
                  checked={selectedAuthors.includes(author)}
                  onChange={() => handleAuthorToggle(author)}
                  className="accent-red-500 peer cursor-pointer"
                />
                <span className="text-gray-700 text-sm work-sans">{he.decode(author)}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthorFilter;