import Link from "next/link";
import { TrendingUp } from "react-feather";

export function TrendingCategory({ popularCategories }) {
    return (
        <div>
            <h2 className="text-lg font-bold mb-4 mt-8 text-red-500 flex gap-4 items-center">
                <TrendingUp stroke="#ef4444" size={20} strokeWidth={2.5} /> Trending Category
            </h2>
            <div className="flex flex-wrap gap-1 items-center md:w-1/2">
                {popularCategories?.map((category) => (
                    <Link
                        key={category.id}
                        href={`/news/${category?.slug}`}
                        target="_blank" // Corrected to use `slug`
                        className={`py-1 border border-black px-4  flex text-black font-slab leading-tight text-sm justify-between hover:text-red-600`}
                    >
                        <span>{category?.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
}