import React from 'react'
import { Facebook, Linkedin, Twitter, Youtube } from "react-feather";
import Link from "next/link";


function HeaderSocial() {
    const socialLinks = [
        { href: "https://www.facebook.com/TheIndependentSG/", icon: <Facebook size={18} stroke="#fff" strokeWidth={1} /> },
        { href: "https://x.com/independentsg?lang=en", icon: <Twitter size={18} stroke="#fff" strokeWidth={1} /> },
        { href: "https://www.linkedin.com/company/the-independent-singapore/?originalSubdomain=sg", icon: <Linkedin size={18} stroke="#fff" strokeWidth={1} /> },
        { href: "https://www.youtube.com/c/TheIndependentSingapore", icon: <Youtube size={18} stroke="#fff" strokeWidth={1} /> },
    ];

    return (
        <>
            <div className="v-line"></div>
            <div className='flex gap-4'>
                {socialLinks.map(({ href, icon }) => (
                    <Link
                        key={href}
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {icon}
                    </Link>
                ))}
            </div>
        </>
    )
}

export default HeaderSocial