import React from "react";
import Link from "next/link";
import Image from "next/image";
import parse from "html-react-parser";
import dayjs from "dayjs";
import he from "he";

const SearchResults = ({ paginatedPosts, closeSearch, capitalize }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
      {paginatedPosts.length > 0 ? (
        paginatedPosts.map(post => (
          <article key={post.id} className="pb-0 border-b group grid grid-cols-[100px_1fr] gap-4">
            <div className="relative overflow-hidden mb-4" style={{ width: "100px", height: "80px" }}>
              <img src={post.featured_image_url} alt="post"  className="object-cover transition-transform h-full duration-200 group-hover:scale-105" />
            </div>
            <div>
              <Link href={`/${post.slug}`} onClick={closeSearch} className="text-sm xl:text-lg mb-2 text-black font-bold line-clamp-2 leading-snug group-hover:text-red-500">
                {parse(post.title.rendered || "")}
              </Link>
              <div className="flex gap-2 flex-wrap items-center">
                {post?.categories_with_names?.length > 0 &&
                  post?.categories_with_names.map((category, index) => (
                    <React.Fragment key={category?.id}>
                      <Link href={`/news/${category?.slug}`} className="text-red-500 font-bold text-sm">
                        {capitalize(he.decode(category.name))}
                      </Link>
                      {index < post?.categories_with_names?.length - 1 && (
                        <span className="text-gray-300 text-xs">|</span>
                      )}
                    </React.Fragment>
                  ))}
                <span className="text-gray-500" style={{ fontWeight: 300 }}>-</span>
                <span className="text-sm font-semibold text-black">{dayjs(post.date).format("MMM D, YYYY")}</span>
              </div>
            </div>
          </article>
        ))
      ) : (
        ''
      )}
    </div>
  );
};

export default SearchResults; 