import React, { useState } from "react";

const TagFilter = ({ 
  search, 
  setSearch, 
  filteredTags, 
  selectedTags, 
  handleTagToggle 
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mt-3 w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full border-b border-gray-200 pb-3 flex justify-between items-center text-sm work-sans text-black font-medium"
      >
        Tag
        <svg
          className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      
      {isOpen && (
        <div className="py-4 pr-2 mt-4" style={{height:'400px', overflowY:'auto'}}>
          <input
            type="text"
            placeholder="Search tags..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-2 mb-4 work-sans text-sm text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-red-500"
          />
          <div className="flex flex-wrap">
            {filteredTags.map(tag => (
              <label key={tag} className="flex items-center gap-2 mb-2 mr-4 cursor-pointer">
                <input
                  type="checkbox"
                  checked={selectedTags.includes(tag)}
                  onChange={() => handleTagToggle(tag)}
                  className="accent-red-500 peer cursor-pointer"
                />
                <span className="text-gray-700 text-sm work-sans">{tag}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TagFilter;