import React from 'react'

function ClearFilter({ sortOrder, selectedCategories, selectedTags, selectedAuthors, onClear}) {
    return (
        <div className="mb-2 pb-4 border-b border-gray-200 mt-6 flex justify-between items-end">
            <span className="text-xl text-black">Filer</span>
            <span
                className={`text-sm work-sans underline flex ${sortOrder === "recent" &&
                    selectedCategories.length === 0 &&
                    selectedTags.length === 0 &&
                    selectedAuthors.length === 0 
                    ? "hidden"
                    : "text-red-600 cursor-pointer"
                    }`}
                onClick={onClear}
            >
                Clear
            </span>
        </div>
    )
}

export default ClearFilter