"use client";
import Link from "next/link";
import React, { useState, useEffect } from "react";
import { Home, Mail, X } from "react-feather";
import { Facebook, Linkedin, Twitter, Youtube } from "react-feather";

const MobileMenu = ({
    isOpen,
    onClose,
}) => {
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    const toggleMenu = (index) => {
        if (openMenuIndex === index) {
            setOpenMenuIndex(null); // close if already open
        } else {
            setOpenMenuIndex(index); // open clicked menu, close others
        }
    };

    // Close menu when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.menu-container')) {
                setOpenMenuIndex(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    const handleClose = () => {
        onClose();
    };
    const mainMenuItems = [
        {
            title: "THE INDEPENDENT SINGAPORE",
            items: [
                { label: "FEATURED NEWS", href: "/news/features" },
                { label: "INTERNATIONAL", href: "/news/international" },
                { label: "SG ECONOMY", href: "/news/sg-economy" },
                { label: "SINGAPORE POLITICS", href: "/news/singapore-politics" },
                { label: "IN THE HOOD", href: "/news/in-the-hood" },
                { label: "CELEBRITY", href: "/news/celebrity" },
                { label: "ENTERTAINMENT", href: "/news/entertainment" },
                { label: "LIFESTYLE", href: "/news/lifestyle" }
            ]
        },
        {
            title: "ABOUT US",
            items: [
                { label: "ABOUT US", href: "/about-us" },
                { label: "OUR PHILOSOPHY ", href: "/our-philosophy" },
                { label: "CONTACT US", href: "/contact-us" },
                { label: "JOIN US", href: "/join-us" },
                { label: "T&Cs", href: "/terms-and-conditions" },
                { label: "PRIVACY POLICY", href: "/privacy-policy" }
            ]
        }
    ];
    const socialLinks = [
        { href: "https://www.facebook.com/TheIndependentSG/", icon: <Facebook size={18} stroke="#000" strokeWidth={1} /> },
        { href: "https://x.com/independentsg?lang=en", icon: <Twitter size={18} stroke="#000" strokeWidth={1} /> },
        { href: "https://www.linkedin.com/company/the-independent-singapore/?originalSubdomain=sg", icon: <Linkedin size={18} stroke="#000" strokeWidth={1} /> },
        { href: "https://www.youtube.com/c/TheIndependentSingapore", icon: <Youtube size={18} stroke="#000" strokeWidth={1} /> },
    ];
    return (
        <div className={`search-wrapper bg-menu ${isOpen ? "block h-full" : "hidden"}`}>
            <div className="container mx-auto px-3 py-3 md:px-24 md:py-6">
                <div onClick={handleClose} className="close">
                    <X stroke="#000" strokeWidth={1} size={42} />
                </div>
                <div className="mt-12 xl:hidden block">
                    <Link href="/" className="text-black font-bold flex mb-2 text-2xl">
                        HOME
                    </Link>
                    {mainMenuItems.map((menu, menuIndex) => (
                        <div key={`menu-${menuIndex}`} className="relative menu-container">
                            <button
                                onClick={() => toggleMenu(menuIndex)}
                                className="flex text-black items-center space-x-1 py-2 tracking-wide font-bold text-2xl"
                            >
                                {menu.title}
                                <svg
                                    className={`w-4 h-4 ml-1 transform transition-transform ${openMenuIndex === menuIndex ? 'rotate-180' : ''}`}
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>

                            <div className={`absolute ${openMenuIndex === menuIndex ? 'block' : 'hidden'} w-48 bg-black text-white shadow-lg z-10`}>
                                <ul className="py-2">
                                    {menu.items.map((item, itemIndex) => (
                                        <li key={`item-${menuIndex}-${itemIndex}`}>
                                            <a
                                                href={item.href}
                                                className={`block px-4 py-2 hover:bg-gray-700 ${item.hasSubmenu ? 'flex items-center justify-between' : ''}`}
                                            >
                                                {item.label}
                                                {item.hasSubmenu && (
                                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                    </svg>
                                                )}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-12 xl:grid hidden xl:grid-cols-2">
                    <div className="flex  gap-12">
                        <a href="/">
                            <img src="/img/logo.png" alt="The Independent Singapore" />
                        </a>
                        <div className="ml-12">
                            <h3 className="text-4xl font-bold mb-10">Quick Links</h3>
                            <ul className="mobile-menu-list">
                                <li><a href="/about-us">About Us</a></li>
                                <li><a href="/our-philosophy">Our Philosophy</a></li>
                                <li><a href="/contact-us">Contact Us</a></li>
                                <li><a href="/join-us">Join Us</a></li>
                                <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="ml-12 text-black">
                        <h3 className="text-3xl font-bold mb-10">Popular Categories</h3>
                        <ul className="mobile-menu-chip">
                            <li><a href="/news/singapore-news">Singapore News</a></li>
                            <li><a href="/news/features">Featured News</a></li>
                            <li><a href="/news/international">International</a></li>
                            <li><a href="/news/sg-economy">SG Economy</a></li>
                            <li><a href="/news/singapore-politics">Singapore Politics</a></li>
                            <li><a href="/news/in-the-hood">In The Hood</a></li>
                            <li><a href="/news/celebrity">Celebrity</a></li>
                            <li><a href="/news/entertainment">Entertainment</a></li>
                            <li><a href="/news/lifestyle">Lifestyle</a></li>
                        </ul>
                        <div className="mt-12 border-t border-gray-300"></div>
                        <div className="mt-12">
                            <h3 className="text-3xl font-bold mb-10">Address</h3>
                            <div className="">
                                <p className="text-xl xl:mb-8">
                                    13 Stamford Rd. #02-11, Capitol Singapore,<br/> Singapore 17890
                                </p>
                                <div className="mt-8">
                                    <Link href="mailto:news@theindependent.sg" className="text-lg flex items-center gap-2 text-black" target="_blank" rel="noopener noreferrer">
                                        <Mail size={24} stroke="#000" strokeWidth={1} style={{ marginTop: '2px' }} /> news@theindependent.sg
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 border-t border-gray-300"></div>
                        <div className="mt-12">
                            <h3 className="text-3xl font-bold mb-10">Follow Us On</h3>
                            <div className="flex space-x-4 menu-social-links">
                                {socialLinks.map(link => (
                                    <Link
                                        key={link.href}
                                        href={link.href}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {link.icon}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
