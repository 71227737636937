import React from 'react'

function Drawer({ isOpen, onClose, children }) {
    return (
        <div
            className={`drawer transition-all duration-300 ${isOpen ? "block" : "hidden"
                } text-white p-0`}
        >
            <div onClick={onClose} className="drawer-overlay cursor-pointer"></div>
            {children}
        </div>
    )
}
export default Drawer


