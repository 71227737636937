"use client"; 
import React, { useEffect, useState } from "react";

function CurrentDate() {
    const [currentDate, setCurrentDate] = useState("");

    useEffect(() => {
        setCurrentDate(
            new Date().toLocaleDateString("en-US", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            })
        );
    }, []);

    return (
        <div className="text-xs lg:text-lg text-white mb-3">
            {currentDate}
        </div>
    );
}

export default CurrentDate;
