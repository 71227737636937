"use client";
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import TurnstileCaptcha from "./turnstile-captcha";

function Subscribe({ isOpen, isClose }) {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  useEffect(() => {
    setMessage(''); // Clear message when form opens/closes
    const subscribedEmail = localStorage.getItem('subscribedEmail');
    if (subscribedEmail) {
      setIsSubscribed(true);
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      setMessage('Please complete the CAPTCHA to subscribe.');
      return;
    }
    setLoading(true);
    setMessage('');

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, status: 'enabled', captchaToken }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage("You've subscribed successfully!");
        localStorage.setItem('subscribedEmail', email);
        setIsSubscribed(true);
        setEmail('');
      } else {
        setMessage(result.error || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to subscribe. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`search-wrapper subscribe-bg ${isOpen ? "block" : "hidden"}`}>
      <div className="container mx-auto px-4 md:px-0 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="pt-24 pr-24 hidden lg:block">
            <div className="text-6xl font-bold text-black leading-tight mt-6">
              <span className="text-white">Stay updated with </span><br />
              The Independent Singapore News
            </div>
            <p className="font-slab text-xl text-white mt-4">
              Never miss a beat with the latest news, insights, and updates from The Independent Singapore News delivered straight to your inbox.
            </p>
          </div>
          <div className="py-4 lg:p-24">
            {!isSubscribed ? (
              <>
                <p className="text-2xl text-gray-500 lg:mt-6">
                  Sign up to receive weekly news and stories from <br className="hidden lg:block" /> theindependent.sg
                </p>
                <form className="mt-8" onSubmit={handleSubmit}>
                  <label htmlFor="email" className="sr-only">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full font-slab p-4 mb-6 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="e-Mail address"
                    required
                  />
                  <TurnstileCaptcha onVerify={setCaptchaToken} />
                  <p className="mb-8 mt-4 text-sm leading-relaxed text-gray-600 font-slab">
                    Your information will be used in accordance with{' '}
                    <Link href="https://theindependent.sg/privacy-policy__trashed-2/" className="underline">
                      theindependentsg's privacy policy
                    </Link>
                    . You may opt out at any time.
                  </p>
                  <button
                    type="submit"
                    disabled={loading || !captchaToken}
                    className={`text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xs text-lg w-full sm:w-auto px-14 py-4 text-center ${loading || !captchaToken ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {loading ? 'Subscribing...' : 'Subscribe'}
                  </button>
                </form>
              </>
            ) : (
              <div className="flex items-center gap-6 flex-col">
                <p className="text-lg">You have subscribed successfully</p>
              </div>
            )}
            {message && (
              <div className="mt-5 text-sm text-gray-700 dark:text-gray-300">
                {message}
              </div>
            )}
          </div>
        </div>
        <div onClick={isClose} className="close" aria-label="Close subscription form">
          <X stroke="#000" strokeWidth={1} size={42} />
        </div>
      </div>
    </div>
  );
}

export default Subscribe;