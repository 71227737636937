import React, { useState } from "react";
import he from "he";


const CategoryFilter = ({ 
  search, 
  setSearch, 
  filteredCategories, 
  selectedCategories, 
  handleCategoryToggle 
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full border-b border-gray-200 pb-3 flex justify-between items-center text-sm work-sans text-black font-medium"
      >
        Category
        <svg
          className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      
      {isOpen && (
        <div className="pt-4">
          <input
            type="text"
            placeholder="Search categories..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="w-full p-2 mb-4 work-sans text-sm text-gray-600 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-red-500"
          />
          <div className="flex flex-wrap">
            {filteredCategories.map(category => (
              <label key={category} className="flex items-center gap-2 mb-2 mr-4 cursor-pointer">
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category)}
                  onChange={() => handleCategoryToggle(category)}
                  className="accent-red-500 peer cursor-pointer"
                />
                <span className="text-gray-700 text-sm work-sans">{he.decode(category)}</span>

              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;