'use client';

import React, { useEffect, useRef, useState } from 'react';

const TurnstileCaptcha = ({ onVerify }) => {
  const captchaRef = useRef(null);
  const [error, setError] = useState(null);
  const initialized = useRef(false);

  const loadScript = () => {
    return new Promise((resolve, reject) => {
      if (window.turnstile) return resolve();
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Turnstile script'));
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    let scriptElement;

    if (!initialized.current) {
      loadScript()
        .then(() => {
          scriptElement = document.querySelector('script[src="https://challenges.cloudflare.com/turnstile/v0/api.js"]');
          if (captchaRef.current) {
            window.turnstile.render(captchaRef.current, {
              sitekey: process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY,
              callback: (token) => {
                onVerify(token);
              },
              theme: 'light',
            });
            initialized.current = true;
          }
        })
        .catch((err) => {
          console.error('Error loading Turnstile:', err);
          setError('Failed to load CAPTCHA. Please try again later.');
        });
    }

    // Cleanup on unmount
    return () => {
      if (scriptElement && document.body.contains(scriptElement)) {
        document.body.removeChild(scriptElement);
      }
      if (window.turnstile && captchaRef.current) {
        window.turnstile.remove(captchaRef.current);
      }
    };
  }, [onVerify]);

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div ref={captchaRef} className="captcha w-full"></div>
    </div>
  );
};

export default TurnstileCaptcha;