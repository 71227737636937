import React from 'react'
import { Home } from 'react-feather'
import Link from 'next/link'

function Menu() {
    const mainMenuItems = [
        {
            title: "THE INDEPENDENT SINGAPORE",
            link: "/news/singapore-news",
            items: [
                { label: "FEATURED NEWS", href: "/news/features" },
                { label: "INTERNATIONAL", href: "/news/international" },
                { label: "SG ECONOMY", href: "/news/sg-economy" },
                { label: "SINGAPORE POLITICS", href: "/news/singapore-politics" },
                { label: "IN THE HOOD", href: "/news/in-the-hood" },
                { label: "CELEBRITY", href: "/news/celebrity" },
                { label: "ENTERTAINMENT", href: "/news/entertainment" },
                { label: "LIFESTYLE", href: "/news/lifestyle" }
            ]
        },
        {
            title: "ABOUT US",
            link: "/about-us",
            items: [
                { label: "OUR PHILOSOPHY ", href: "/our-philosophy" },
                { label: "CONTACT US", href: "/contact-us" },
                { label: "JOIN US", href: "/join-us" },
                { label: "T&Cs", href: "/terms-and-conditions" },
                { label: "PRIVACY POLICY", href: "/privacy-policy" }
            ]
        }
    ];
    return (
        <div className="">
            <div className="container bg-slate-200 py-0 mx-auto px-6">
                <div className="flex items-center gap-8">
                    <Link href="/">
                        <Home size={26} stroke="#000" strokeWidth={1.5} />
                    </Link>
                    {mainMenuItems.map((menu, menuIndex) => (
                        <div key={`menu-${menuIndex}`} className="relative group">
                            <button className="flex text-black items-center space-x-1 py-4 tracking-wide font-bold">
                                <Link href={menu.link}>
                                    {menu.title}
                                </Link>
                                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                            </button>

                            <div className="absolute hidden group-hover:block w-48 bg-black text-white shadow-lg z-10">
                                <ul className="py-2">
                                    {menu.items.map((item, itemIndex) => (
                                        <li key={`item-${menuIndex}-${itemIndex}`}>
                                            <a
                                                href={item.href}
                                                className={`block px-4 py-2 tracking-wide hover:bg-gray-700 ${item.hasSubmenu ? 'flex items-center justify-between' : ''}`}
                                            >
                                                {item.label}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Menu
