"use client";
import React, { useState, useMemo } from "react";
import { X } from "react-feather";
import { TrendingCategory } from "../treading-category";
import PaginationControls from "../pagination-controls";
import SearchInput from "../../ui/input";
import { useMediaQuery } from 'react-responsive';
import SearchResults from "./search-results";
import FilterPanel from './filter-panel';

const SearchBar = ({
  isOpen,
  closeSearch,
  searchQuery,
  setSearchQuery,
  filteredPosts,
  popularCategories,
  allPosts
}) => {
  const [sortOrder, setSortOrder] = useState("recent");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [searchAuthor, setSearchAuthor] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const isLargeScreen = useMediaQuery({ minWidth: 1928 });
  const ITEMS_PER_PAGE = isLargeScreen ? 14 : 10;

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const categories = [...new Set(allPosts.flatMap(post => post.categories_with_names?.map(cat => cat.name) || []))]
    .sort((a, b) => a.localeCompare(b));

  const filteredCategories = categories.filter(category =>
    category.toLowerCase().includes(search.toLowerCase())
  );

  const handleCategoryToggle = (category) => {
    setSelectedCategories(prev => prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category]);
    setCurrentPage(1);
  };

  const tags = [...new Set(allPosts.flatMap(post => post.tags_with_names?.map(tag => tag.name) || []))]
    .sort((a, b) => a.localeCompare(b));

  const filteredTags = tags.filter(tag =>
    tag.toLowerCase().includes(searchTag.toLowerCase())
  );

  const handleTagToggle = (tag) => {
    setSelectedTags(prev => prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]);
    setCurrentPage(1);
  };

  const authors = [...new Set(allPosts.map(post => post.author_name || "").filter(name => name))]
    .sort((a, b) => a.localeCompare(b));


  const filteredAuthors = authors.filter(author =>
    author.toLowerCase().includes(searchAuthor.toLowerCase())
  );

  const handleAuthorToggle = (author) => {
    setSelectedAuthors(prev => prev.includes(author) ? prev.filter(t => t !== author) : [...prev, author]);
  };

  const sortedPosts = useMemo(() => {
    if (!filteredPosts) return [];

    let posts = filteredPosts.filter(post => {
      const matchesCategories = selectedCategories.length === 0 ||
        post.categories_with_names?.some(cat => selectedCategories.includes(cat.name));

      const matchesTags = selectedTags.length === 0 ||
        post.tags_with_names?.some(tag => selectedTags.includes(tag.name));

      const matchesAuthors = selectedAuthors.length === 0 ||
        selectedAuthors.includes(post.author_name);

      return matchesCategories && matchesTags && matchesAuthors;
    });

    return posts.sort((a, b) => (sortOrder === "recent" ? new Date(b.date) - new Date(a.date) : new Date(a.date) - new Date(b.date)));
  }, [filteredPosts, sortOrder, selectedCategories, selectedTags, selectedAuthors]);
  const totalPages = Math.ceil(sortedPosts.length / ITEMS_PER_PAGE);
  const paginatedPosts = sortedPosts.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) setCurrentPage(page);
  };

  const handleClose = () => {
    setSearch("");
    setSearchTag("");
    setSelectedCategories([]);
    setSelectedAuthors([]);
    setSelectedTags([]);
    setSortOrder("recent");
    setCurrentPage(1);
    closeSearch();
  };

  const handleSearchClear = () => {
    setSearch("");
    setSearchTag("");
    setSelectedCategories([]);
    setSelectedAuthors([]);
    setSelectedTags([]);
    setSortOrder("recent");
    setCurrentPage(1);
    setSearchQuery("");
  };

  return (
    <div className={`search-wrapper ${isOpen ? "block h-full" : "hidden"}`}>
      <div className="container mx-auto px-3 py-3 md:px-24 md:py-6">
        <div onClick={handleClose} className="close">
          <X stroke="#000" strokeWidth={1} size={42} />
        </div>
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          clearSearch={handleSearchClear}
          placeholder="Search by title, content..."
          className="text-lg md:text-2xl"
        />

        {!searchQuery ? <TrendingCategory popularCategories={popularCategories} /> : ''}

        <div className="search-cnt pb-8 pr-4">
          {searchQuery && (
            <div className="bg-white" style={{ height: "96%" }}>
              <div className="flex justify-between items-center">
                <span className="text-gray-500 work-sans mt-4 mb-4 flex">{sortedPosts.length} posts found</span>
                <button
                  className="lg:hidden p-3 bg-gray-100 hover:bg-gray-200 rounded-full relative"
                  onClick={() => setIsFilterModalOpen(true)}
                >
                  {(selectedCategories.length > 0 || selectedTags.length > 0 || selectedAuthors.length > 0 || sortOrder !== "recent") && (
                    <span className="absolute top-2 right-2 w-2 h-2 bg-red-500 rounded-full"></span>
                  )}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="#000" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                  </svg>
                </button>
              </div>
              {isFilterModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 lg:hidden">
                  <div className="fixed inset-y-0 right-0 w-[90%]  bg-white p-4 overflow-y-auto">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-xl font-bold">Filters</h2>
                      <button
                        onClick={() => setIsFilterModalOpen(false)}
                        className="p-2 bg-gray-100 hover:bg-gray-200 rounded-full"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#000" className="w-4 h-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                    <FilterPanel
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      selectedCategories={selectedCategories}
                      selectedTags={selectedTags}
                      setSelectedCategories={setSelectedCategories}
                      selectedAuthors={selectedAuthors}
                      setSelectedAuthors={setSelectedAuthors}
                      setSelectedTags={setSelectedTags}
                      search={search}
                      setSearch={setSearch}
                      filteredCategories={filteredCategories}
                      searchTag={searchTag}
                      setSearchTag={setSearchTag}
                      searchAuthor={searchAuthor}
                      setSearchAuthor={setSearchAuthor}
                      filteredTags={filteredTags}
                      filteredAuthors={filteredAuthors}
                      handleCategoryToggle={handleCategoryToggle}
                      handleTagToggle={handleTagToggle}
                      handleAuthorToggle={handleAuthorToggle}
                    />
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 h-full">
                <div className="col-span-2">
                  <SearchResults
                    paginatedPosts={paginatedPosts}
                    closeSearch={closeSearch}
                    capitalize={capitalize}
                  />
                  <div className="w-full flex justify-center">
                    {totalPages > 1 && (
                      <PaginationControls
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={goToPage}
                      />
                    )}
                  </div>
                </div>
                <div className="border-l border-width-1 border-gray-200 pl-6 hidden lg:block">
                  <FilterPanel
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    selectedCategories={selectedCategories}
                    selectedTags={selectedTags}
                    setSelectedCategories={setSelectedCategories}
                    setSelectedAuthors={setSelectedAuthors}
                    selectedAuthors={selectedAuthors}
                    setSelectedTags={setSelectedTags}
                    search={search}
                    setSearch={setSearch}
                    filteredCategories={filteredCategories}
                    searchTag={searchTag}
                    setSearchTag={setSearchTag}
                    searchAuthor={searchAuthor}
                    setSearchAuthor={setSearchAuthor}
                    filteredTags={filteredTags}
                    filteredAuthors={filteredAuthors}
                    handleCategoryToggle={handleCategoryToggle}
                    handleTagToggle={handleTagToggle}
                    handleAuthorToggle={handleAuthorToggle}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
