import React from 'react';

const SortOrder = ({ sortOrder, setSortOrder }) => {
    return (
        <>
            <div className="text-xl text-black mb-4 pb-4 border-b border-gray-200">Sort</div>
            <div className="mb-4 space-y-2">
                <label className="flex items-center gap-2 work-sans text-gray-600 text-sm">
                    <input
                        type="radio"
                        checked={sortOrder === "recent"}
                        onChange={() => setSortOrder("recent")}
                        className="transform scale-125 accent-red-500 cursor-pointer"
                    />
                    Most Recent
                </label>
                <label className="flex items-center gap-2 work-sans text-gray-600 text-sm">
                    <input
                        type="radio"
                        checked={sortOrder === "oldest"}
                        onChange={() => setSortOrder("oldest")}
                        className="transform scale-125 accent-red-500 cursor-pointer"
                    />
                    Oldest to Newest
                </label>
            </div>
        </>
    );
};

export default SortOrder;