import React from 'react';
import SortOrder from './sort-order';
import ClearFilter from './clear-filter';
import CategoryFilter from './category-filter';
import TagFilter from './tag-filter';
import AuthorFilter from './author-filter';

const FilterPanel = ({
  sortOrder,
  setSortOrder,
  selectedCategories,
  selectedTags,
  selectedAuthors,
  setSelectedCategories,
  setSelectedTags,
  setSelectedAuthors,
  search,
  setSearch,
  searchTag,
  setSearchTag,
  searchAuthor,
  setSearchAuthor,
  filteredCategories,
  filteredTags,
  filteredAuthors,
  handleCategoryToggle,
  handleTagToggle,
  handleAuthorToggle,
}) => {
  return (
    <div className="sticky top-0">
      <SortOrder
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
      <ClearFilter
        sortOrder={sortOrder}
        selectedCategories={selectedCategories}
        selectedTags={selectedTags}
        selectedAuthors={selectedAuthors}
        onClear={() => {
          setSortOrder("recent");
          setSelectedCategories([]);
          setSelectedTags([]);
          setSelectedAuthors([]);
        }}
      />
      <CategoryFilter
        search={search}
        setSearch={setSearch}
        filteredCategories={filteredCategories}
        selectedCategories={selectedCategories}
        handleCategoryToggle={handleCategoryToggle}
      />
      <TagFilter
        search={searchTag}
        setSearch={setSearchTag}
        filteredTags={filteredTags}
        selectedTags={selectedTags}
        handleTagToggle={handleTagToggle}
      />
      <AuthorFilter
        searchAuthor={searchAuthor}
        setSearchAuthor={setSearchAuthor}
        filteredAuthors={filteredAuthors}
        selectedAuthors={selectedAuthors}
        handleAuthorToggle={handleAuthorToggle}
      />
    </div>
  );
};

export default FilterPanel;