import React from "react";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationPrevious,
  PaginationNext,
  PaginationEllipsis,
} from "../ui/pagination";

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  const goToPage = (page) => {
    if (page >= 1 && page <= totalPages) onPageChange(page);
  };

  const pages = Array.from({ length: 3 }, (_, i) => currentPage - 1 + i)
    .filter((p) => p >= 1 && p <= totalPages);

  return (
    <Pagination className="mt-6">
      <PaginationContent>
        {currentPage > 1 && (
          <span className="flex items-center mr-4 cursor-pointer" onClick={() => goToPage(currentPage - 1)}>
            <PaginationItem>
              <PaginationPrevious />
            </PaginationItem>
            <span className="work-sans text-black font-medium">Prev</span>
          </span>
        )}

        {currentPage > 2 && (
          <>
            <PaginationItem>
              <PaginationLink onClick={() => goToPage(1)} isActive={currentPage === 1}>
                1
              </PaginationLink>
            </PaginationItem>
            {currentPage > 3 && <PaginationItem><PaginationEllipsis /></PaginationItem>}
          </>
        )}

        {pages.map((page) => (
          <PaginationItem key={page}>
            <PaginationLink onClick={() => goToPage(page)} isActive={page === currentPage}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}

        {currentPage < totalPages - 2 && (
          <>
            <PaginationItem><PaginationEllipsis /></PaginationItem>
            <PaginationItem>
              <PaginationLink onClick={() => goToPage(totalPages)} isActive={currentPage === totalPages}>
                {totalPages}
              </PaginationLink>
            </PaginationItem>
          </>
        )}

        {currentPage < totalPages && (
          <span className="flex items-center ml-4 cursor-pointer" onClick={() => goToPage(currentPage + 1)}>
            <span className="work-sans text-black font-medium">Next</span>
            <PaginationItem>
              <PaginationNext />
            </PaginationItem>
          </span>
        )}
      </PaginationContent>
    </Pagination>
  );
};

export default PaginationControls;